import { useGlobalState } from '@/hooks';
import StoreBadge from '../StoreBadge/StoreBadge';
import {
  AppBadgeContainer,
  AppBadgeMainText,
  AppBadgeSubText,
  AppBadgeTextContainer,
  StoreBadgesWrapper,
} from './AppBadge.styles';

interface AppBadgeProps {
  mainText?: string;
  subText?: string;
  appStoreUrl?: string;
  googlePlayUrl?: string;
}

const AppBadge: React.FC<AppBadgeProps> = ({ mainText, subText, appStoreUrl, googlePlayUrl }) => {
  const { pmtCurrentLanguageCode } = useGlobalState();

  return (
    <AppBadgeContainer>
      <AppBadgeTextContainer>
        {mainText && <AppBadgeMainText>{mainText}</AppBadgeMainText>}
        {subText && <AppBadgeSubText>{subText}</AppBadgeSubText>}
      </AppBadgeTextContainer>
      <StoreBadgesWrapper>
        <StoreBadge platform='apple' url={appStoreUrl} languageCode={pmtCurrentLanguageCode} />
        <StoreBadge platform='google' url={googlePlayUrl} languageCode={pmtCurrentLanguageCode} />
      </StoreBadgesWrapper>
    </AppBadgeContainer>
  );
};

export default AppBadge;
