import { TRACKING_PARAM_PARCEL_ID } from '@/constants';
import {
  ButtonColorKey,
  ButtonSize,
  ButtonVariant,
  type ISectionWrapper,
  IconAlignment,
  SectionWrapperVariant,
} from '@/interfaces';
import { gtmTrackingWidget } from '@/utils';
import { useRouter } from 'next/router';
import { type FC, type FormEventHandler, useRef } from 'react';
import LocalShipping from '../../public/images/icons/local_shipping.svg';
import {
  DesktopSectionTitleSmall,
  FormButton,
  FormWrapper,
  Input,
  WidgetSectionWrapper,
} from './TrackingWidget.styles';

const TrackingWidget: FC<ISectionWrapper> = (props) => {
  const router = useRouter();
  const inputRef = useRef<HTMLInputElement>(null);
  const { variant } = props;

  const handleSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const trackingId = inputRef.current?.value;
    if (!trackingId) return;
    gtmTrackingWidget();
    // PMC -> PMT, must call sever-side requests in PMT
    void router.push({ pathname: '/track-my-parcel', query: { [TRACKING_PARAM_PARCEL_ID]: trackingId } });
  };

  return (
    <WidgetSectionWrapper variant={variant}>
      <DesktopSectionTitleSmall>Track your parcel here</DesktopSectionTitleSmall>
      <FormWrapper onSubmit={handleSubmit}>
        <Input
          ref={inputRef}
          name={TRACKING_PARAM_PARCEL_ID}
          autoComplete='off'
          placeholder='Enter tracking ID'
          data-lpignore='true' //remove extension icon
        />
        <FormButton
          type='submit'
          variant={ButtonVariant.Primary}
          color={ButtonColorKey.Yellow}
          icon={LocalShipping}
          size={ButtonSize.Medium}
          mobileSize={ButtonSize.Large}
          alignment={IconAlignment.Left}
        >
          {variant === SectionWrapperVariant.RowDisplay ? 'TRACK PARCEL' : 'TRACK'}
        </FormButton>
      </FormWrapper>
    </WidgetSectionWrapper>
  );
};

export default TrackingWidget;
