import { type IAppBanner, LanguageCode } from '@/interfaces';
import { languageFieldsMapping } from '@/utils';
import callContentful from './base.fetchers';

type GetAppBanner = (args: {
  isPreviewMode: boolean;
  languageCode?: LanguageCode;
  slug: string;
}) => Promise<IAppBanner[]>;

export const getAppBanner: GetAppBanner = async ({ isPreviewMode, languageCode = LanguageCode.English, slug }) => {
  const mappedLanguageFields = languageFieldsMapping(languageCode);
  const query = `
    {
      appBannerCollection(
        preview: ${isPreviewMode}
        locale: "${mappedLanguageFields.locale}"
        limit: 10
      ) {
        items {
          ${['mainText', 'subText', 'buttonText'].map((field) => mappedLanguageFields.getField(field))}
          appIcon {
            url
          }
          buttonLinkIos
          buttonLinkAndroid
          pageSlugs
          country
        }
      }
    }
  `;

  const response = await callContentful(query, isPreviewMode, slug, 'appBanner.fetchers.getAppBanner');

  const appBannerData = response?.data?.appBannerCollection?.items?.filter(Boolean) || null;
  return appBannerData;
};
