import { Typography } from '@/components';
import { BreadcrumbsSectionContainer } from '@/components/Breadcrumbs/Breadcrumbs.styles';
import { TextStyles } from '@/constants';
import { SectionThemeKey } from '@/interfaces';
import styled, { css } from 'styled-components';

export const BannerContainer = styled.div<{ fullWidth?: boolean }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: grid;
    align-items: center;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 40px;

    ${({ fullWidth }) =>
      fullWidth &&
      css`
      display: block;
      max-width: 736px;
    `}
  }
`;

export const BannerInnerContainer = styled.div`
  ${BreadcrumbsSectionContainer} {
    padding: 0;
  }
`;

export const HeroAssetWrapper = styled.div`
  padding-top: 40px;
  
  & > img {
    width: 100%;
    height: auto !important;
    object-fit: contain !important;
  }

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding-top: 0;
  }
`;

export const SubTitle = styled(Typography).attrs({
  variant: TextStyles['Overline'],
})`
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  margin-bottom: ${({ theme }) => theme.spacing[8]};
`;

export const Title = styled(Typography).attrs({
  variant: TextStyles['Heading 1'],
})`
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;  
  overflow: hidden;
  display: none;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: -webkit-box;
  }
`;

export const MobileTitle = styled(Title)`
  display: -webkit-box;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: none;
  }
`;

export const SubText = styled(Typography).attrs({
  variant: TextStyles['Body Text Large'],
})`
  margin-top: ${({ theme }) => theme.spacing[8]};
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;  
  overflow: hidden; 
  white-space: pre-line;
  display: none;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: -webkit-box;
  }
`;

export const MobileSubText = styled(SubText)`
  display: -webkit-box;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: none;
  }
`;

export const ButtonGroupWrapper = styled.div`
  margin-top: ${({ theme }) => theme.spacing[32]};
`;

export const DefaultBannerWrapper = styled.div<{
  sectionTheme: SectionThemeKey;
}>`
  position: relative;
  padding: ${({ theme }) => `${theme.spacing[20]} ${theme.spacing[20]} ${theme.spacing[40]} ${theme.spacing[20]}`};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    padding: ${({ theme }) => `${theme.spacing[40]} ${theme.spacing[20]} ${theme.spacing[40]} ${theme.spacing[20]}`};

    ${BreadcrumbsSectionContainer} {
      margin-bottom: ${({ theme }) => theme.spacing[30]};
    }
  }

  background-color: ${({ sectionTheme, theme }) => {
    return sectionTheme === SectionThemeKey.Light ? theme.colors.surface02 : theme.colors.darkBlue;
  }};

  ${Title} {
    color: ${({ sectionTheme, theme }) => {
      return sectionTheme === SectionThemeKey.Light ? theme.colors.midnight : theme.colors.highEmphasisWhite;
    }};
  }

  ${SubTitle}, ${SubText} {
    color: ${({ sectionTheme, theme }) => {
      return sectionTheme === SectionThemeKey.Light ? theme.colors.mediumEmphasisBlack : theme.colors.highEmphasisWhite;
    }};
  }
`;
