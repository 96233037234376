import type { IMenuItem, ISecondaryItem } from '@/interfaces';
import { gtmClickNavigationItem, isOpenInNewTab } from '@/utils';
import Link from 'next/link';
import { Fragment } from 'react';
import {
  DropdownSecondaryMenuItem,
  DropdownSecondaryMenuItemTitle,
  Image,
  ImageWrapper,
  SecondaryMenuContainer,
} from './SecondaryMenuList.styles';

const SecondaryMenuList: React.FC<{ data: IMenuItem['innerData'] }> = ({ data }) => {
  const renderIcon = (icon: ISecondaryItem['icon']) => {
    if (!icon?.url) return null;

    return (
      <ImageWrapper>
        <Image
          src={icon.url}
          alt={icon?.description || 'Menu Icon'}
          fill
          sizes='100vw'
          style={{
            objectFit: 'contain',
          }}
        />
      </ImageWrapper>
    );
  };

  const renderSecondaryItem = (item: ISecondaryItem) => {
    const menuItemTitle = (
      <DropdownSecondaryMenuItemTitle
        onClick={() => {
          if (!item.url || !item.title) return;
          gtmClickNavigationItem(item.title);
        }}
      >
        {item.title}
      </DropdownSecondaryMenuItemTitle>
    );

    if (!item.url) {
      return (
        <DropdownSecondaryMenuItem>
          {renderIcon(item.icon)}
          {menuItemTitle}
        </DropdownSecondaryMenuItem>
      );
    }

    return (
      <DropdownSecondaryMenuItem>
        {renderIcon(item.icon)}
        <Link href={item.url ?? '#'} passHref target={isOpenInNewTab(item.url)}>
          {menuItemTitle}
        </Link>
      </DropdownSecondaryMenuItem>
    );
  };

  return (
    <SecondaryMenuContainer>
      {data?.map((item: ISecondaryItem) => (
        <Fragment key={item.title}>{renderSecondaryItem(item)}</Fragment>
      ))}
    </SecondaryMenuContainer>
  );
};

export default SecondaryMenuList;
