import styled from 'styled-components';

const GraphicImgContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  width: 100%;
  height: 100%;

   ${({ theme }) => theme.mediaBreakpointUp.md} {
    width: fit-content;
  }
`;

export const DesktopContainer = styled(GraphicImgContainer)(({ theme }) => ({
  display: 'none',

  [`${theme.mediaBreakpointUp.md}`]: {
    display: 'block',
  },
}));

export const MobileContainer = styled(GraphicImgContainer)(({ theme }) => ({
  display: 'block',

  [`${theme.mediaBreakpointUp.md}`]: {
    display: 'none',
  },
}));
