import { Button, SectionTitleSmall, SectionWrapper } from '@/components';
import { SectionWrapperVariant } from '@/interfaces';
import styled from 'styled-components';

export const WidgetSectionWrapper = styled(SectionWrapper)`
  padding-top: ${({ theme }) => theme.spacing[16]};
  padding-bottom: ${({ theme }) => theme.spacing[16]};

  ${({ variant }) =>
    variant === SectionWrapperVariant.RowDisplay &&
    `
    height: 80px;

    ${FormWrapper} {
      flex: 1;
    }
  `}

  ${({ variant }) =>
    variant === SectionWrapperVariant.StickyDisplay &&
    `
    border-top: none;

    ${SectionTitleSmall} {
      display: none;
    }
  `}
`;

export const FormButton = styled(Button)`
  height: 45px;
  border-radius: 0;
  width: max-content;
`;

export const FormWrapper = styled.form<{ on?: string }>`
  display: flex;
  width: 100%;
  overflow: hidden;
  border: 1px solid ${({ theme }) => theme.colors.inactiveBlack};
  border-radius: ${({ theme }) => theme.spacing[4]};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    width: fit-content;
  }
`;

export const Input = styled.input<{ on?: string }>`
  all: unset;
  width: inherit;
  flex: 1;
  padding: 0 ${({ theme }) => theme.spacing[14]};

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    width: 177px;
  }
`;

export const DesktopSectionTitleSmall = styled(SectionTitleSmall)`
  display: none;

  ${({ theme }) => theme.mediaBreakpointUp.md} {
    display: block;
  }
`;
