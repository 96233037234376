import { ContentTypeIds, REDIS_HOMEPAGE_KEY, SlugRoutes, entryPages } from '@/constants';
import type { IPageSlug } from '@/interfaces';
import getConfig from 'next/config';
import type { NextRouter } from 'next/router';
import { getCountryBySlug } from './country.utils';

export const checkIsPreviewModeRequest = (query: NodeJS.Dict<string | string[]>): boolean => {
  const { serverRuntimeConfig } = getConfig();
  const isPreviewTokenCorrect = query?.previewToken === serverRuntimeConfig.previewModeAccessToken;
  const isPreviewMode = query?.preview === 'true' && isPreviewTokenCorrect;

  return isPreviewMode;
};

export const getCachePageSlugFromUrl = (type: ContentTypeIds, pageData: Record<string, any>): string => {
  if (!entryPages.includes(type)) return '';
  const SLASH = '/';

  switch (type) {
    case ContentTypeIds.tag:
      return SLASH + pageData.type + (!pageData.slug ? '' : SLASH + pageData.slug);
    case ContentTypeIds.article:
      return SLASH + pageData.articleType + SLASH + pageData.slug;
    case ContentTypeIds.event:
      return `/events/${pageData.slug}`;
    case ContentTypeIds.carrier:
      return SlugRoutes.carriers + SLASH + pageData.slug;
    case ContentTypeIds.companyDirectory:
      return SlugRoutes.companies + (!pageData.slug ? '' : SLASH + pageData.slug);
    case ContentTypeIds.market:
      return SlugRoutes.markets + SLASH + pageData.slug;
    default: //landing page
      return pageData.slug ? SLASH + pageData.slug : REDIS_HOMEPAGE_KEY;
  }
};

export const splitTradelanePageSlug = (slug: string) => {
  const TRADELANE_PAGE_PATTERN = /^([a-z-]+)-to-([a-z-]+)$/;
  const tradelanePageMatch = slug.match(TRADELANE_PAGE_PATTERN);

  if (!tradelanePageMatch) return null;

  const originSlug = tradelanePageMatch[1];
  const destinationSlug = tradelanePageMatch[2];

  const isOriginValid = getCountryBySlug(originSlug);
  const isDestinationValid = getCountryBySlug(destinationSlug);

  if (!isOriginValid || !isDestinationValid) return null;

  return { originSlug, destinationSlug };
};

/**
 * Checks if the current page path matches any of the provided page slugs
 *
 * @param pageSlugs - Array of page slug objects, each containing a description (slug)
 * @param asPath - Current page path from Next.js router
 * @returns boolean - True if current path matches any slug or if no slugs provided
 *
 * @example
 * // Homepage matches
 * checkPageSlugMatching([{ description: '/' }], '/') // true
 * checkPageSlugMatching([{ description: '/' }], '/?query=123') // true
 *
 * // Exact matches with query parameters
 * checkPageSlugMatching([{ description: '/page?id=1' }], '/page?id=1') // true
 * checkPageSlugMatching([{ description: '/page?id=1' }], '/page?id=2') // false
 *
 * // Path prefix matches
 * checkPageSlugMatching([{ description: '/blog' }], '/blog') // true
 * checkPageSlugMatching([{ description: '/blog' }], '/blog/post-1') // true
 * checkPageSlugMatching([{ description: '/blog' }], '/blog?category=news') // true
 * checkPageSlugMatching([{ description: '/blog' }], '/blogpost') // false
 * checkPageSlugMatching([{ description: '/blog/post1' }], '/blog') // false
 *
 * // Show on all pages when no slugs provided
 * checkPageSlugMatching([], '/any-path') // true
 * checkPageSlugMatching(null, '/any-path') // true
 */
export const checkPageSlugMatching = (pageSlugs: IPageSlug[] | undefined | null, asPath: NextRouter['asPath']) => {
  // If no page slugs defined, show on all pages
  if (!pageSlugs?.length) {
    return true;
  }

  // If all page slugs are empty, show on all pages
  if (pageSlugs?.every((pageSlug) => !pageSlug.description)) {
    return true;
  }

  // Check if current path matches any of the page slugs
  const pageMatch = pageSlugs.some(({ description: slug }: IPageSlug) => {
    // Homepage match: exact "/" or with query params "/?param=value"
    if (slug === '/' && (asPath === '/' || asPath.startsWith('/?'))) {
      return true;
    }

    // For other paths, check if current path starts with the slug
    if (slug !== '/' && slug?.includes('?')) {
      // Exact match required for slugs with query parameters
      return asPath === slug;
    }
    if (slug !== '/') {
      // For slugs without query parameters, check if path starts with slug
      // and is followed by end, slash, or question mark
      if (slug && asPath.startsWith(slug)) {
        const nextChar = asPath.charAt(slug.length);
        return nextChar === '' || nextChar === '/' || nextChar === '?';
      }
    }

    return false;
  });

  return pageMatch;
};
