import { getMerchant } from '@/modules/pmt/fetchers/merchant.fetchers';
import type { IShipment } from '@/modules/pmt/interfaces';
import { sendSentryError } from '@/utils/error.utils';
import { useEffect } from 'react';
import { useTimer } from './useTimer';

const updateShipmentsWithMerchantInfo = (shipments: IShipment[], merchantMap: Map<string, any>) => {
  return shipments.map((shipment) => {
    if (shipment.data.merchantRef && merchantMap.has(shipment.data.merchantRef)) {
      return {
        ...shipment,
        data: {
          ...shipment.data,
          merchantInfo: merchantMap.get(shipment.data.merchantRef),
        },
      };
    }
    return shipment;
  });
};

interface UseShipmentMerchantInfoProps {
  shipments: {
    overview: IShipment[];
    delivered: IShipment[];
  };
  shipmentsLoaded: boolean;
  setShipments: React.Dispatch<
    React.SetStateAction<{
      overview: IShipment[];
      delivered: IShipment[];
    }>
  >;
}

export const useShipmentMerchantInfo = ({ shipments, shipmentsLoaded, setShipments }: UseShipmentMerchantInfoProps) => {
  const timer = useTimer();

  useEffect(() => {
    const fetchMerchantData = async () => {
      // Get unique merchant refs from both overview and delivered shipments
      const merchantRefs = new Set([
        ...shipments.overview
          .filter((s) => s.data.merchantRef && !s.data.merchantInfo)
          .map((s) => s.data.merchantRef as string),
        ...shipments.delivered
          .filter((s) => s.data.merchantRef && !s.data.merchantInfo)
          .map((s) => s.data.merchantRef as string),
      ]);

      if (merchantRefs.size === 0) return;

      try {
        timer.start();

        // Fetch merchant data in parallel
        const merchantPromises = Array.from(merchantRefs).map(async (ref) => getMerchant(ref));
        const merchants = await Promise.all(merchantPromises);

        // Create a map of merchant data
        const merchantMap = new Map(Array.from(merchantRefs).map((ref, index) => [ref, merchants[index]]));

        // Update both overview and delivered shipments with merchant data
        setShipments((prev) => ({
          overview: updateShipmentsWithMerchantInfo(prev.overview, merchantMap),
          delivered: updateShipmentsWithMerchantInfo(prev.delivered, merchantMap),
        }));
      } catch (err) {
        console.error('Error fetching merchant data:', err);
      } finally {
        timer.stop();

        // Send error if loading took more than 4 seconds
        const loadTime = timer.get();
        if (loadTime > 4000) {
          sendSentryError('Merchant data loading is taking too long.', {
            merchantRefsCount: merchantRefs.size,
            loadTime: `${loadTime}ms`,
          });
        }
      }
    };

    if (shipmentsLoaded) {
      void fetchMerchantData();
    }
  }, [shipments.overview, shipments.delivered, shipmentsLoaded, setShipments, timer]);
};
