import { Tooltip } from '@/components';
import { SlugRoutes } from '@/constants';
import { useOverflowing, useResponsive } from '@/hooks';
import { ResponsiveCollection } from '@/interfaces';
import Link from 'next/link';
import { type FC, useEffect, useRef } from 'react';
import { CarrierDisplayNameWrapper, CarrierLogo, CarrierName, CarrierNameWrapper } from './Carrier.styles';

interface ICarrierName {
  logo?: string;
  name?: string;
  slug?: string;
}

const Carrier: FC<ICarrierName> = ({ logo, name, slug }) => {
  const ref = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);
  const limit = 1;
  const { isDesktop } = useResponsive([ResponsiveCollection.Desktop]);
  const isOverflowing = useOverflowing({
    ref,
    limit,
    lineHeight: 24,
  });

  useEffect(() => {
    if (textRef.current) {
      if (isOverflowing) {
        textRef.current.classList.add('has-tooltip');
      } else {
        textRef.current.classList.remove('has-tooltip');
      }
    }
  }, [isOverflowing, ref.current, textRef.current]);

  const LinkWrapper = () => (
    <Link href={`${SlugRoutes.carriers}/${slug}` ?? '#'} passHref>
      <CarrierName>{name}</CarrierName>
    </Link>
  );

  const renderChild = () => {
    const hasTooltipCondition = isOverflowing && isDesktop;
    if (hasTooltipCondition) {
      return (
        <Tooltip content={name}>
          <div ref={textRef} style={{ width: '100%' }}>
            {slug ? <LinkWrapper /> : <CarrierName>{name}</CarrierName>}
          </div>
        </Tooltip>
      );
    }

    return (
      <div ref={textRef} style={{ width: '100%' }}>
        {slug ? <LinkWrapper /> : <CarrierName>{name}</CarrierName>}
      </div>
    );
  };

  return (
    <CarrierNameWrapper>
      <CarrierLogo src={logo as string} alt={name || 'Carrier Logo'} unoptimized />
      <CarrierDisplayNameWrapper ref={ref}>{renderChild()}</CarrierDisplayNameWrapper>
    </CarrierNameWrapper>
  );
};

export default Carrier;
