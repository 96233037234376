import { ButtonIconByFunction } from '@/constants';
import { ButtonColorKey, ButtonFunctionKey, type ButtonProps, ButtonVariant } from '@/interfaces';
import { checkIsActualDownloadButton, getPathnameFromURL, getStylingOfVariant, isExternalURL } from '@/utils';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { type AnchorHTMLAttributes, type ButtonHTMLAttributes, type MouseEventHandler, forwardRef } from 'react';
import { ButtonText } from './Button.styles';
import { GoogleBrandedButton } from './GoogleBrandedButton';

const Button: React.ForwardRefRenderFunction<
  HTMLAnchorElement | HTMLButtonElement,
  ButtonProps & (AnchorHTMLAttributes<'a'> | ButtonHTMLAttributes<any>)
> = (props, ref) => {
  const {
    children,
    variant,
    icon,
    function: _function,
    openInNewTab: _openInNewTab,
    href: _href,
    onClick,
    primaryButtonText,
    secondaryButtonText,
    type,
    truncate,
    hideIcon,
    ...restProps
  } = props;
  const { asPath } = useRouter();

  const buttonLink = !props.disabled ? props.buttonLink : undefined;
  const isEmailButton = _function === ButtonFunctionKey.Email && !!props.email;
  const isLinkButton = _function === ButtonFunctionKey.Link && !!buttonLink;
  const isGoogleBrandedButton = _function === ButtonFunctionKey.GoogleBranded;
  const isActualDownloadButton = checkIsActualDownloadButton(props);
  const href =
    _href ||
    (isLinkButton || isActualDownloadButton ? buttonLink : isEmailButton ? `mailto:?to=${props.email}` : undefined);
  const _variant =
    restProps.color === ButtonColorKey.White || restProps.color === ButtonColorKey.Transparent
      ? ButtonVariant.Outlined
      : variant;
  const { ButtonComponent, ButtonLink } = getStylingOfVariant(_variant);

  // This icon is to obey the styling of origin design
  const Icon = !hideIcon && (icon || (_function && ButtonIconByFunction[_function]) || null);
  const isRelTag = isExternalURL(href);
  const openInNewTab = _openInNewTab === undefined ? isRelTag : _openInNewTab;

  const customButtonOnClick = () => {
    if (onClick) onClick();
  };

  const buttonClickObject: any = {
    onClick: customButtonOnClick,
  };

  if (isGoogleBrandedButton) {
    return (
      <GoogleBrandedButton
        buttonLink={buttonLink}
        primaryButtonText={primaryButtonText}
        secondaryButtonText={secondaryButtonText}
      />
    );
  }

  if (href && isEmailButton) {
    return (
      <ButtonLink {...restProps} {...buttonClickObject} href={href} ref={ref} icon={Icon}>
        <ButtonText isPMTButton={restProps.isPMTButton} truncate={truncate}>
          {primaryButtonText || children}
        </ButtonText>
        {Icon && <Icon />}
      </ButtonLink>
    );
  }

  if (href) {
    const currentPathname: string = asPath.split('?').shift() || '';
    const buttonLinkPathname: string = getPathnameFromURL(href || '');
    const arePathnamesTheSame = currentPathname === buttonLinkPathname;

    const handleButtonClick: MouseEventHandler = (e) => {
      if (restProps.disabled) {
        e.preventDefault();
        return;
      }

      // Do not use default scroll behavior becasue it is not working properly,
      // workaround is to use window.history.replaceState to change the url.
      // TODO: Figure out why it is not working properly.
      if (href.startsWith('#')) {
        e.preventDefault();
        window.history.replaceState('', '', href);
        document.querySelector(href)?.scrollIntoView();
        customButtonOnClick();
        return;
      }

      if (!arePathnamesTheSame || openInNewTab) {
        customButtonOnClick();
        return;
      }

      e.preventDefault();
      customButtonOnClick();
    };

    const buttonClickObject: any = {
      onClick: handleButtonClick,
    };

    const relTag = {
      rel: 'noopener',
    };

    const download = {
      download: true,
    };

    const renderButtonLink = () => (
      <ButtonLink
        {...buttonClickObject}
        {...(isRelTag && relTag)}
        href={arePathnamesTheSame ? href : undefined}
        ref={ref}
        target={openInNewTab || isActualDownloadButton ? '_blank' : undefined}
        icon={Icon}
        {...(isActualDownloadButton && download)}
        {...restProps}
      >
        <ButtonText isPMTButton={restProps.isPMTButton} truncate={truncate}>
          {primaryButtonText || children}
        </ButtonText>
        {Icon && <Icon />}
      </ButtonLink>
    );

    if (arePathnamesTheSame) {
      return renderButtonLink();
    }

    return (
      <Link href={href ?? '#'} passHref legacyBehavior>
        {renderButtonLink()}
      </Link>
    );
  }

  return (
    <ButtonComponent
      {...buttonClickObject}
      ref={ref}
      type={type || 'button'}
      variant={variant}
      icon={Icon}
      {...restProps}
    >
      <ButtonText isPMTButton={restProps.isPMTButton} truncate={truncate}>
        {primaryButtonText || children}
      </ButtonText>
      {Icon && <Icon />}
    </ButtonComponent>
  );
};

export default forwardRef(Button);
