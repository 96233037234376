import { TextStyles } from '@/constants';
import type { IMenuItem } from '@/interfaces';
import { gtmClickNavigationItem, isOpenInNewTab } from '@/utils';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { AnnouncementLabel } from '../../Header.styles';
import {
  AccordionContainer,
  Arrow,
  MainMenuTitle,
  MenuTitle,
  PanelContentWrapper,
  PanelTitle,
  PanelTitleWrapper,
  PanelWrapper,
} from './AccordionMenu.styles';

export type PanelKey = number | string | null;

interface AccordionMenuProps {
  data?: IMenuItem[];
  isCollapseAll: boolean;
}

const AccordionMenu: React.FC<AccordionMenuProps> = ({ data, isCollapseAll }) => {
  const [selectedPanels, setSelectedPanels] = useState<PanelKey[]>([]);

  const handleClickOnPanel = (panelKey: number) => {
    setSelectedPanels(
      selectedPanels.includes(panelKey)
        ? selectedPanels.filter((item) => item !== panelKey)
        : [...selectedPanels, panelKey],
    );
  };

  useEffect(() => {
    if (isCollapseAll) {
      setSelectedPanels([]);
    }
  }, [isCollapseAll]);

  if (!data?.length) return null;

  const renderMenuTitle = ({ title, announcementLabel }: IMenuItem) => {
    return (
      <MainMenuTitle variant={TextStyles['Nav Item Text']}>
        {title}
        {announcementLabel && <AnnouncementLabel>{announcementLabel}</AnnouncementLabel>}
      </MainMenuTitle>
    );
  };

  const renderSubMenuTitle = ({ title, url }: IMenuItem) => {
    return (
      <MenuTitle
        variant={TextStyles['Body Text Small']}
        onClick={() => {
          if (!url || !title) return;
          gtmClickNavigationItem(title);
        }}
      >
        {title}
      </MenuTitle>
    );
  };

  return (
    <AccordionContainer>
      {data?.map((item: IMenuItem, index: number) => {
        if (!item.innerData?.length) {
          return !item.url ? (
            <PanelTitle key={index} onClick={() => handleClickOnPanel(index)}>
              {renderMenuTitle(item)}
            </PanelTitle>
          ) : (
            <PanelTitle key={index} onClick={() => handleClickOnPanel(index)}>
              <Link href={item.url ?? '#'} passHref target={isOpenInNewTab(item.url)}>
                {renderMenuTitle(item)}
              </Link>
            </PanelTitle>
          );
        }

        return (
          <PanelWrapper key={index} isExpand={selectedPanels.includes(index)}>
            <PanelTitleWrapper onClick={() => handleClickOnPanel(index)}>
              {renderMenuTitle(item)}
              <Arrow />
            </PanelTitleWrapper>
            <PanelContentWrapper>
              {item.innerData?.map((innerItem: IMenuItem, innerIndex: number) => {
                return !innerItem.url ? (
                  <React.Fragment key={innerIndex}>{renderSubMenuTitle(innerItem)}</React.Fragment>
                ) : (
                  <Link href={innerItem.url ?? '#'} key={innerIndex} passHref target={isOpenInNewTab(innerItem.url)}>
                    {renderSubMenuTitle(innerItem)}
                  </Link>
                );
              })}
            </PanelContentWrapper>
          </PanelWrapper>
        );
      })}
    </AccordionContainer>
  );
};

export default AccordionMenu;
