import {
  MILESTONE_MATCHERS,
  MOCK_CARRIER_REFERENCE_KEY,
  PENDING_STAGE,
  STAGE_MATCHERS,
  analyticsWithIssue,
} from '@/modules/pmt/constants';
import {
  type ICarrierWithFirstFoundEvent,
  type IParcelEvent,
  type IProgressStage,
  type IShipmentData,
  type IShipmentDataDTO,
  ShipmentSearchStatus,
  TrackingMilestone,
} from '@/modules/pmt/interfaces';
import dayjs from 'dayjs';
import { Timestamp } from 'firebase/firestore';

export const buildTranslateEventsObj: (rawParcel: any) => null | Record<string, unknown> = (rawParcel: any) => {
  if (!rawParcel || typeof rawParcel !== 'object') return null;

  const allowKeys = [
    'origin_locality',
    'destination_locality',
    'last_event',
    'events',
    'origin_country',
    'destination_country',
    'event_location',
    'has_location_mapped', // boolean
  ];

  const outputObject: Record<string, unknown> = {};
  allowKeys.map((key) => {
    if (rawParcel[key] !== null || typeof rawParcel[key] !== 'undefined') {
      outputObject[key] = rawParcel[key];
    }
  });

  return Object.keys(outputObject).length > 0 ? outputObject : null;
};

export type EventsById = {
  [eventId: string]: IParcelEvent;
};

export const sortEventList = (eventList: EventsById): IParcelEvent[] => {
  return Object.values(eventList).sort((firstEvent: IParcelEvent, secondEvent: IParcelEvent) => {
    const isSameYearMonthAndDay: boolean =
      dayjs(firstEvent.date).format('DD MM YY') === dayjs(secondEvent.date).format('DD MM YY');

    if (isSameYearMonthAndDay) {
      if (firstEvent.isFinalEvent) return -1;
      if (secondEvent.isFinalEvent) return 1;
    }

    const firstEventDate = new Date(firstEvent.date).getTime();
    const secondEventDate = new Date(secondEvent.date).getTime();

    return secondEventDate - firstEventDate;
  });
};

export const generateCarrierListFromEventList = (inputEventList: IParcelEvent[]) =>
  [...inputEventList].reverse().reduce<ICarrierWithFirstFoundEvent[]>((foundCarrierList, event) => {
    const isCarrierFound = foundCarrierList.find((carrier) => carrier.id === event.carrier.id);

    if (!isCarrierFound) {
      foundCarrierList.unshift({
        ...event.carrier,
        firstFoundInEvent: event,
      });
    }

    return foundCarrierList;
  }, []);

/**
 * This util is created to support cases where shipments don't have search_status,
 * For example: Email Sync shipments, shipments before Horus-0
 * shipment.search_status is introduced in Horus-0
 *
 * There are 4 statuses: "timeout", "pending", "fetched", "scraping_with_carrier".
 * This function defaults to "timeout"
 */
export const getShipmentSearchStatus = (shipment?: IShipmentData) => {
  if (!shipment) return ShipmentSearchStatus.Timeout;

  // search_status is always "fetched" for ES || SME shipments, we must check for parcel.events
  // to see if a shipment is a "Pending" shipment or not
  if (shipment.isEmailSyncShipment || shipment.isSMEShipment) {
    return shipment.parcel?.events?.length ? ShipmentSearchStatus.Fetched : ShipmentSearchStatus.Timeout;
  }

  // New shipments from Horus-0 that have search_status
  return shipment.searchStatus;
};

export const HTMLEntitiesConvert = (text: string) => {
  if (typeof window === 'undefined') {
    return;
  }

  const txt = new DOMParser().parseFromString(text, 'text/html');

  return txt.documentElement.textContent;
};

export const isEmailSyncShipment = (shipment?: IShipmentData): boolean => {
  return shipment?.parcelUserTag?.includes('email_sync') ?? false;
};

export const isSMEShipment = (shipment?: IShipmentData): boolean => {
  return shipment?.parcelUserTag?.includes('sme') ?? false;
};

export const isOpenShipment = (shipment?: IShipmentData): boolean => {
  return !shipment?.parcel?.parcelId;
};

export const isOwnShipment = (shipment: IShipmentData, userUuid?: string) => {
  if (!userUuid || !shipment.additionalInfo?.userId) return false;

  return userUuid === shipment.additionalInfo.userId;
};

export const isMockCarrierShipment = (shipment?: IShipmentData) => {
  const shipmentCarrierReference = shipment?.carrierReference;
  if (shipmentCarrierReference === MOCK_CARRIER_REFERENCE_KEY) {
    return true;
  }

  const parcelCarrierReference = shipment?.parcel?.carrier?.carrierId;
  if (parcelCarrierReference === MOCK_CARRIER_REFERENCE_KEY) {
    return true;
  }

  return false;
};

export const getShipmentStage = (shipment: IShipmentData): IProgressStage => {
  const matched = STAGE_MATCHERS.find((condition) => condition.matcher(shipment));

  if (!matched) {
    console.error('Invariant Violation: Could not match shipment with any stage! Defaulted to Pending stage.');
    return PENDING_STAGE;
  }

  return matched.stage;
};

type GetMilestone = (shipment: IShipmentData) => TrackingMilestone;

export const getShipmentMilestone: GetMilestone = (shipment) => {
  const matched = MILESTONE_MATCHERS.find((condition) => condition.matcher(shipment));

  if (!matched) {
    console.error('Invariant Violation: Could not match shipment with any milestone! Defaulted to Pending milestone.');
    return TrackingMilestone.Pending;
  }

  return matched.milestone;
};

export const getShipmentIssue = (shipment: IShipmentData): boolean => {
  return shipment.parcel?.lastEvent?.analytics
    ? analyticsWithIssue.includes(shipment.parcel?.lastEvent?.analytics)
    : false;
};

export const getShipmentTime = (time?: string | Timestamp): string | Date | undefined => {
  if (time instanceof Timestamp) {
    return time.toDate();
  }
  return time;
};

export const getShipmentImportedDate = (shipment: IShipmentDataDTO) => {
  return getShipmentTime(
    shipment.parcel?.events?.[0]?.event_time ||
      shipment.parcel?.events?.[0]?.imported_date ||
      shipment.imported_date ||
      shipment.parcel?.imported_date,
  );
};
