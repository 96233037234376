import Link from 'next/link';
import type { FC } from 'react';

interface ILink {
  href?: string;
}

const LinkWrapper: FC<ILink> = ({ href, children }) => {
  return href ? (
    <Link href={href ?? '#'} passHref>
      {children}
    </Link>
  ) : (
    <>{children}</>
  );
};

export default LinkWrapper;
