import { PageWithGraphic, SEOSection } from '@/components';
import { ContentTypeName, CookieKey, MasterTranslation } from '@/constants';
import { unsubscribeAllShipments } from '@/fetchers';
import { useGlobalState } from '@/hooks';
import type { IPmtPage } from '@/modules/pmt/interfaces';
import { capitalize, getPmtLangHref, gtmClickUnsubAllConfirm } from '@/utils';
import Cookies from 'js-cookie';
import type React from 'react';
import { useState } from 'react';
import { BackToMyParcelsButton, PageTitle, UnsubscribeAllWrapper, UnsubscribeButton } from './UnsubscribeAll.styles';

interface UnsubscribeAllProps {
  pmtPageData?: IPmtPage | null;
}

const UnsubscribeAll: React.FC<UnsubscribeAllProps> = ({ pmtPageData }) => {
  const umsAccessToken = Cookies.get(CookieKey.UmsAccessToken);
  const browserUuid = Cookies.get(CookieKey.BrowserUuid);
  const accessToken = umsAccessToken || browserUuid;

  const { t, pmtCurrentLanguageCode, userInfo, setUserInfo } = useGlobalState();
  const unsubscribeTranslated = t(MasterTranslation.Unsubscribe);

  const [isConfirmed, setIsConfirmed] = useState<boolean>(false);

  const onUnsubscribeAllShipments = async () => {
    if (!accessToken) return;
    gtmClickUnsubAllConfirm();

    await unsubscribeAllShipments({ accessToken });
    setIsConfirmed(true);
    userInfo &&
      setUserInfo({
        ...userInfo,
        defaultOptIn: false,
      });
  };

  const mainText = isConfirmed
    ? t(MasterTranslation.UnsubAllSuccessMainText)
    : t(MasterTranslation.UnsubAllConfirmationMainText);

  const unsubscribeButton = unsubscribeTranslated && !isConfirmed && (
    <UnsubscribeButton onClick={onUnsubscribeAllShipments}>{unsubscribeTranslated}</UnsubscribeButton>
  );

  const foundSeoSection = pmtPageData?.sectionList?.find(
    (section: any) => capitalize(section.__typename) === ContentTypeName.SeoSection,
  );

  return (
    <>
      <UnsubscribeAllWrapper>
        <PageWithGraphic>
          <PageTitle>{mainText}</PageTitle>
          {unsubscribeButton}
          <BackToMyParcelsButton href={getPmtLangHref(pmtCurrentLanguageCode)}>
            {t(MasterTranslation.BackToMyParcels)}
          </BackToMyParcelsButton>
        </PageWithGraphic>
      </UnsubscribeAllWrapper>
      {foundSeoSection && <SEOSection {...foundSeoSection} />}
    </>
  );
};

export default UnsubscribeAll;
