import { TextStyles } from '@/constants';
import styled from 'styled-components';
import Typography from '../Typography';

export const AppBadgeContainer = styled.div(({ theme }) => ({
  width: '100%',
  padding: `${theme.spacing[24]} 0`,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'space-between',
  borderTop: `1px solid ${theme.colors.pressedWhite}`,
  rowGap: theme.spacing[24],

  [`${theme.mediaBreakpointUp.md}`]: {
    flexDirection: 'row',
    rowGap: theme.spacing[16],
    columnGap: theme.spacing[80],
    paddingTop: theme.spacing[48],
    marginTop: theme.spacing[48],
    marginBottom: theme.spacing[48],
  },
}));

export const AppBadgeTextContainer = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing[8],
  width: '100%',

  [`${theme.mediaBreakpointUp.md}`]: {
    width: 640,
  },
}));

export const AppBadgeMainText = styled(Typography).attrs({
  variant: TextStyles['Heading 2'],
})(({ theme }) => ({
  color: theme.colors.highEmphasisWhite,
}));

export const AppBadgeSubText = styled(Typography).attrs({
  variant: TextStyles['Body Text Large'],
})(({ theme }) => ({
  color: theme.colors.highEmphasisWhite,
  fontSize: theme.fontSizes[14],
  lineHeight: theme.lineHeight[22],

  [`${theme.mediaBreakpointUp.md}`]: {
    fontSize: theme.fontSizes[16],
    lineHeight: theme.lineHeight[24],
  },
}));

export const StoreBadgesWrapper = styled.div(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing[16],
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-start',
  width: '100%',

  a: {
    display: 'flex',
    alignItems: 'center',
  },

  [`${theme.mediaBreakpointUp.md}`]: {
    width: 'auto',
  },
}));
