import type { IAppBanner } from '@/interfaces';
import Image from 'next/image';
import { useState } from 'react';
import {
  AppBannerButton,
  AppBannerCloseButton,
  AppBannerContainer,
  AppBannerContent,
  AppBannerMainText,
  AppBannerSubText,
} from './AppBanner.styles';

const AppBanner: React.FC<IAppBanner & { buttonLink: string }> = ({
  appIcon,
  mainText,
  subText,
  buttonText,
  buttonLink,
}) => {
  const [isShown, setIsShown] = useState(true);

  if (!isShown) return null;

  return (
    <AppBannerContainer>
      <AppBannerCloseButton onClick={() => setIsShown(false)} />
      {appIcon?.url && <Image src={appIcon.url} alt='App Icon' width={40} height={40} />}
      <AppBannerContent>
        {mainText && <AppBannerMainText>{mainText}</AppBannerMainText>}
        {subText && <AppBannerSubText>{subText}</AppBannerSubText>}
      </AppBannerContent>
      <AppBannerButton truncate={2} href={buttonLink}>
        {buttonText}
      </AppBannerButton>
    </AppBannerContainer>
  );
};

export default AppBanner;
