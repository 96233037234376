import { UNKNOWN_COUNTRY } from '@/constants';
import { useCalculateHeight, useGeo, useGlobalState } from '@/hooks';
import { type IAppBanner, type IFooter, type IHeader, PMCAdType } from '@/interfaces';
import { PMTAdType } from '@/modules/pmt/constants';
import { checkPageSlugMatching, getRandomData, isEU, showCookieBannerPopup } from '@/utils';
import type { DeviceInfo } from '@/utils/detectOs.utils';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { type ReactChild, useCallback, useEffect, useMemo, useState } from 'react';
import AppBanner from '../AppBanner';
import CCPA from './CCPA';
import CookieBanner from './CookieBanner';
import Footer from './Footer';
import Header from './Header';
import { CustomPMCAdsense, CustomPMTAdsense, LayoutWrapper, Main, StickyCollapseMenu } from './Layout.styles';

const HeaderBanner = dynamic(async () => import('./HeaderBanner'), { ssr: false });

interface LayoutProps {
  children: ReactChild;
  footer?: IFooter[] | null;
  header?: IHeader;
  onlyLogo?: boolean;
  appBanner?: IAppBanner[];
  deviceInfo?: DeviceInfo;
}

const Layout: React.FC<LayoutProps> = ({ children, footer, header, onlyLogo, appBanner, deviceInfo }) => {
  const { isErrorPage, isPrivacyPage, isTrackingPage, isMyParcelsPage } = useGlobalState();
  const { geoInfo } = useGeo();
  const [hasCookieBanner, setCookieBanner] = useState(false);
  const hideCookieAndQC = isErrorPage || isPrivacyPage || isTrackingPage;
  const { asPath } = useRouter();

  const randomFooter = useMemo(() => {
    if (!footer) return null;

    const _footer = Array.isArray(footer) ? footer : [footer];

    // Filter footers that match current country
    const matchingFooters = _footer.filter((footerEntry) => {
      return footerEntry.appBadgeCountry?.includes(geoInfo.country);
    });

    if (!matchingFooters.length) {
      // If there is no footer entries, return null
      if (!_footer?.length) return null;

      // If there is no matching footer with app badge country,
      // return the latest published footer entry with no app badge
      return {
        ..._footer[0],
        hideAppBadge: true,
      };
    }
    // Get random footer from filtered list
    return getRandomData<IFooter>(matchingFooters);
  }, [footer, geoInfo]);

  useEffect(() => {
    const _hasCookieBanner =
      !hideCookieAndQC &&
      showCookieBannerPopup() &&
      geoInfo.country === UNKNOWN_COUNTRY && //if no geoInfo, will show Quantcast
      !isEU(geoInfo);
    setCookieBanner(_hasCookieBanner);
  }, [geoInfo, isErrorPage, isPrivacyPage, isTrackingPage]);

  useEffect(() => {
    const CCPA = document.getElementById('pm_ccpa_container');

    const observer = new MutationObserver((mutationsList) => {
      // This callback function will be called whenever there are changes in the DOM
      mutationsList.forEach((mutation) => {
        if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
          const firstAddedNode = mutation.addedNodes[0] as HTMLElement;
          const offsetHeight = firstAddedNode.offsetHeight;
          if (offsetHeight > 0) setCookieBanner(false);
        }
      });
    });

    if (CCPA) observer.observe(CCPA, { childList: true });
  }, []);

  const renderQuantcast = () => {
    if (hideCookieAndQC) {
      return null;
    }
    return <CCPA />;
  };

  const renderCookie = () => {
    if (!hasCookieBanner || hideCookieAndQC) {
      return null;
    }
    return <CookieBanner />;
  };

  const top = useCalculateHeight(['header', 'header-banner']);

  const renderPMCAdsense = useCallback(() => {
    if (isTrackingPage || isMyParcelsPage) return null;

    return <CustomPMCAdsense variant={PMCAdType.TOP_OF_PMC_PAGE} id='ads-top-of-page' className='ads-top-of-page' />;
  }, [isTrackingPage, isMyParcelsPage]);

  const renderPMTAdsense = useCallback(() => {
    if (!isMyParcelsPage) return null;

    return <CustomPMTAdsense variant={PMTAdType.PMT_HEADER} id='ads-pmt-header' className='ads-pmt-header' />;
  }, [isMyParcelsPage]);

  const _appBanner = useMemo(() => {
    // Show app banner on mobile (simulator/emulator or real device) only
    if (!appBanner?.length || !deviceInfo?.isMobile || !geoInfo.fetched) return null;

    const matchingAppBanners = appBanner.filter((appBannerEntry) => {
      const countryMatch = appBannerEntry.country?.includes(geoInfo.country);
      const pageMatch = checkPageSlugMatching(appBannerEntry.pageSlugs, asPath);
      return countryMatch && pageMatch;
    });

    if (!matchingAppBanners.length) return null;

    // A/B testing - Get random app banner from filtered list
    const randomAppBanner = getRandomData<IAppBanner>(matchingAppBanners);

    // Show app banner if it has at least mainText or subText
    if (!randomAppBanner.mainText && !randomAppBanner.subText) return null;

    return randomAppBanner;
  }, [appBanner, asPath, deviceInfo, geoInfo]);

  const renderAppBanner = () => {
    if (!_appBanner) return null;
    const buttonLink = deviceInfo?.isIOS ? _appBanner.buttonLinkIos : _appBanner.buttonLinkAndroid;
    return <AppBanner {..._appBanner} buttonLink={buttonLink} />;
  };

  return (
    <LayoutWrapper id='layout'>
      {renderAppBanner()}
      {renderPMCAdsense()}
      <Header {...header} onlyLogo={onlyLogo} />
      <StickyCollapseMenu id='sticky-collapse-menu' top={top} />
      {!onlyLogo && !isErrorPage && <HeaderBanner />}
      {renderPMTAdsense()}
      <Main id='main'>{children}</Main>
      {!isErrorPage && randomFooter && <Footer {...randomFooter} onlyLogo={onlyLogo} />}
      {renderQuantcast()}
      {renderCookie()}
    </LayoutWrapper>
  );
};

export default Layout;
