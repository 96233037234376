import { useGlobalState } from '@/hooks';
import type { IMenuItem } from '@/interfaces';
import { isAbsoluteUrl, isOpenInNewTab } from '@/utils';
import Link from 'next/link';
import { useRouter } from 'next/router';
import type React from 'react';
import { useMemo } from 'react';
import { AnnouncementLabel } from '../../Header.styles';
import { SingleMenuItemTitle } from './SingleMenuItem.styles';

const SingleMenuItem: React.FC<{ data: IMenuItem }> = ({ data }) => {
  const { asPath } = useRouter();
  const { pageUrl } = useGlobalState();

  const isActive = useMemo(() => {
    const isURL = isAbsoluteUrl(data?.url);
    return isURL ? pageUrl === data?.url : asPath === data?.url;
  }, [pageUrl]);

  const menuTitle = (
    <SingleMenuItemTitle isActive={isActive}>
      {data?.title}
      {data?.announcementLabel && <AnnouncementLabel>{data.announcementLabel}</AnnouncementLabel>}
    </SingleMenuItemTitle>
  );

  return !data?.url ? (
    menuTitle
  ) : (
    <Link href={data?.url ?? '#'} passHref target={isOpenInNewTab(data?.url)}>
      {menuTitle}
    </Link>
  );
};

export default SingleMenuItem;
