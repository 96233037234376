import { MasterTranslation } from '@/constants';
import { useGlobalState } from '@/hooks';
import type { IShipmentData } from '@/modules/pmt/interfaces';
import { useMemo } from 'react';

export const useShipmentInfo = (shipment?: IShipmentData) => {
  const { t } = useGlobalState();

  const shipmentLabel: string | undefined = useMemo(() => {
    if (!shipment) return '';

    const { additionalInfo, lineItems, orderTracking, parcel } = shipment;

    if (additionalInfo?.label) return additionalInfo.label;

    if (lineItems?.length) return lineItems[0].productName;

    if (orderTracking?.orderId) return `${t(MasterTranslation.ParcelCardOrderIdText)} ${orderTracking.orderId}`;

    if (parcel?.parcelId) return `${t(MasterTranslation.ParcelCardTrackingIdText)} ${parcel.parcelId}`;

    return '';
  }, [shipment, t]);

  const shipmentLogo: string | undefined = useMemo(() => {
    if (!shipment) return;

    const { merchantInfo, additionalInfo, isSMEShipment } = shipment;
    if (isSMEShipment) return merchantInfo?.logo;

    return additionalInfo?.orderSourceLogo;
  }, [shipment]);

  const shipmentMerchantName: string | undefined = useMemo(() => {
    if (!shipment) return;
    const { isSMEShipment, merchantInfo, orderTracking, additionalInfo } = shipment;

    if (isSMEShipment) return merchantInfo?.merchantName;
    return orderTracking?.orderSourceName ?? additionalInfo?.senderDomain;
  }, [shipment]);

  return {
    shipmentLabel,
    shipmentLogo,
    shipmentMerchantName,
  };
};
