export interface DeviceInfo {
  isIOS: boolean;
  isAndroid: boolean;
  isMobile: boolean;
  userAgent: string;
}

// Utility function to detect mobile OS from user agent
export const detectMobileOS = (userAgent: string): DeviceInfo => {
  const ua = userAgent.toLowerCase();

  const isIOS = /iphone|ipad|ipod/.test(ua);
  const isAndroid = ua.includes('android');
  const isMobile = isIOS || isAndroid;

  return {
    isIOS,
    isAndroid,
    isMobile,
    userAgent: ua,
  };
};
