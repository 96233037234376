import { AssetRenderer, Container, TrackingWidget } from '@/components';
import { useGlobalState } from '@/hooks';
import { type IPageOverviewBanner, SectionThemeKey, SectionWrapperVariant } from '@/interfaces';
import { gtmClickOverviewBannerSignups, replaceHighlightString } from '@/utils';
import type React from 'react';
import ButtonGroup from '../../ButtonGroup';
import { BannerContainer, BannerInnerContainer, HeroAssetWrapper } from '../../DefaultBanner/DefaultBanner.styles';
import GraphicImg from '../../GraphicImg';
import WidgetTextSection from '../WidgetTextSection';
import {
  BannerWithTrackingWidgetWrapper,
  ButtonGroupWrapper,
  TrackingWidgetMobileWrapper,
  TrackingWidgetWrapper,
} from './BannerWithTrackingWidget.styles';

const DEFAULT_LOGIN_TITLE = 'Welcome back!';

const BannerWithTrackingWidget: React.FC<IPageOverviewBanner> = ({
  title,
  titleMobile,
  subText,
  subTextMobile,
  loginTitle,
  loginMobileTitle,
  loginSubtext,
  priority,
  buttons,
  image,
  imageMobile,
  theme,
  heroAsset,
}) => {
  const { isLoggedIn, userInfo } = useGlobalState();

  const formattedLoginTitle = loginTitle && replaceHighlightString(loginTitle, 'username', userInfo?.firstName || '');

  const formattedMobileLoginTitle =
    loginMobileTitle && replaceHighlightString(loginMobileTitle, 'username', userInfo?.firstName || '');

  const renderTextSection = () => {
    let textObject = {
      mobileTitle: titleMobile,
      desktopTitle: title,
      mobileSubText: subTextMobile ?? '',
      desktopSubText: subText ?? '',
    };

    if (isLoggedIn) {
      textObject = {
        mobileTitle: userInfo?.firstName && formattedMobileLoginTitle ? formattedMobileLoginTitle : DEFAULT_LOGIN_TITLE,
        desktopTitle: userInfo?.firstName && formattedLoginTitle ? formattedLoginTitle : DEFAULT_LOGIN_TITLE,
        mobileSubText: loginSubtext ?? '',
        desktopSubText: loginSubtext ?? '',
      };
    }

    return <WidgetTextSection {...textObject} />;
  };

  return (
    <>
      <BannerWithTrackingWidgetWrapper sectionTheme={theme || SectionThemeKey.Light}>
        <GraphicImg includedWigdet={true} image={image} imageMobile={imageMobile} priority={priority} />
        <Container style={{ position: 'relative' }}>
          <BannerContainer fullWidth={!heroAsset}>
            <BannerInnerContainer>
              {renderTextSection()}
              {!!buttons?.length && (
                <ButtonGroupWrapper>
                  <ButtonGroup
                    buttons={buttons}
                    buttonClick={() => {
                      gtmClickOverviewBannerSignups();
                    }}
                  />
                </ButtonGroupWrapper>
              )}
            </BannerInnerContainer>

            {heroAsset && (
              <HeroAssetWrapper>
                <AssetRenderer
                  asset={heroAsset}
                  imageProps={{
                    width: 540,
                    height: 232,
                    style: { width: 540, height: 232, maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' },
                    decoding: 'sync',
                  }}
                />
              </HeroAssetWrapper>
            )}
          </BannerContainer>
        </Container>
        <TrackingWidgetWrapper>
          <TrackingWidget variant={SectionWrapperVariant.RowDisplay} />
        </TrackingWidgetWrapper>
      </BannerWithTrackingWidgetWrapper>
      <TrackingWidgetMobileWrapper>
        <TrackingWidget variant={SectionWrapperVariant.BlockDisplay} />
      </TrackingWidgetMobileWrapper>
    </>
  );
};

export default BannerWithTrackingWidget;
