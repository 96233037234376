import { useRef } from 'react';

interface TimerResult {
  start: () => void;
  stop: () => void;
  get: () => number;
}

export const useTimer = (): TimerResult => {
  const startTimeRef = useRef<number | null>(null);
  const endTimeRef = useRef<number | null>(null);

  const start = () => {
    startTimeRef.current = Date.now();
  };

  const get = (): number => {
    if (!startTimeRef.current || !endTimeRef.current) return 0;
    return endTimeRef.current - startTimeRef.current;
  };

  const stop = () => {
    endTimeRef.current = Date.now();
  };

  return {
    start,
    get,
    stop,
  };
};
