import { TextStyles } from '@/constants';
import { ButtonSize, ButtonVariant } from '@/interfaces';
import styled from 'styled-components';
import CloseIcon from '../../public/images/icons/close.svg';
import Button from '../Button';
import Typography from '../Typography';

export const AppBannerContainer = styled.div(({ theme }) => ({
  width: '100%',
  backgroundColor: theme.colors.highEmphasisWhite,
  padding: theme.spacing[12],
  display: 'flex',
  gap: theme.spacing[8],
}));

export const AppBannerContent = styled.div(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  rowGap: theme.spacing[4],
}));

export const AppBannerMainText = styled(Typography).attrs({
  variant: TextStyles['Heading 4'],
})(({ theme }) => ({
  color: theme.colors.highEmphasisBlack,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 2,
  WebkitBoxOrient: 'vertical',
}));

export const AppBannerSubText = styled(Typography).attrs({
  variant: TextStyles['Caption Text'],
})(({ theme }) => ({
  color: theme.colors.mediumEmphasisBlack,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  display: '-webkit-box',
  WebkitLineClamp: 3,
  WebkitBoxOrient: 'vertical',
}));

export const AppBannerButton = styled(Button).attrs({
  variant: ButtonVariant.Link,
  size: ButtonSize.Small,
})({
  flexBasis: 64,
  minWidth: 64,
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  margin: 'auto 0',
  marginLeft: 'auto',
  textAlign: 'center',
});

export const AppBannerCloseButton = styled(CloseIcon)(({ theme }) => ({
  minWidth: 24,
  height: 24,

  path: {
    fill: theme.colors.mediumEmphasisBlack,
  },
}));
