import { ContentTypeName, RichTextEntries } from '@/constants';
import {
  type IArticleProps,
  type IEvent,
  type IRichText,
  type ITag,
  RightPanelType,
  type ThumbnailProps,
} from '@/interfaces';
import type { IPmtOnboardingPopup } from '@/modules/pmt/interfaces';

export const allThumbnailDataModify = (
  data: {
    tags?: {
      items: any[];
    };
    [key: string]: any;
  }[],
  sorting = true,
  sortByField: 'date' | 'startDate' | 'endDate' = 'date',
): ThumbnailProps[] => {
  if (!data) return [];

  const modifiedData = data?.map((article) => ({
    ...article,
    tags: article?.tags?.items.filter(Boolean) || [],
  })) as ThumbnailProps[];

  if (!sorting) return modifiedData;

  return modifiedData.sort((prev, next) => {
    const _next = new Date(next?.[sortByField] as string).getTime();
    const _prev = new Date(prev?.[sortByField] as string).getTime();
    return _prev > _next ? -1 : 1;
  });
};

export const relatedThumbnailDataModify = (data: any): ThumbnailProps[] => {
  if (!data) return [];

  return allThumbnailDataModify(data?.linkedFrom?.articles?.items);
};

export const richTextModify = (content: IRichText): IRichText | null => {
  if (!content) return null;

  return {
    ...content,
    links: {
      ...content?.links,
      assets: {
        ...content?.links?.assets,
        block: content?.links?.assets?.block || [],
      },
      entries: {
        ...content?.links?.entries,
        block: content?.links?.entries?.block
          ? content.links?.entries.block.map((blockItem: any) => {
              if (blockItem?.__typename === RichTextEntries.LeadGenBanner) {
                return {
                  ...blockItem,
                  buttons: blockItem?.buttons?.items?.filter(Boolean) || [],
                };
              }

              return blockItem;
            })
          : [],
      },
    },
  };
};

export const articleDataModify = (data: any): IArticleProps => {
  return {
    ...data,
    tags: data.tags?.items?.filter(Boolean) || [],
    content: richTextModify(data.content),
    rightSectionList: rightSectionListModify(data.rightSectionList),
  };
};

export const wordCloudDataModify = (data: any) => {
  return {
    ...data,
    wordBubbles: data?.wordBubbles?.items?.filter(Boolean) || [],
  };
};

export const leadGenBannerDataModify = (data: any) => {
  return {
    ...data,
    buttons: data?.buttons?.items?.filter(Boolean) || [],
  };
};

export const PageOverviewBannerDataModify = (data: any) => {
  return {
    ...data,
    buttons: data?.buttons?.items?.filter(Boolean) || [],
  };
};

export const eventCollectionDataModify = (data: any) => {
  if (!data) return null;
  return data.map((event: any) => ({
    ...event,
    tags: event?.tags?.items?.filter(Boolean) || [],
  }));
};

export const eventListDataModify = (data: any) => {
  if (!data) return null;
  return {
    ...data,
    eventList: eventCollectionDataModify(data?.eventList?.items?.filter(Boolean)),
  };
};

export const wrapperDataModify = (data: any) => {
  if (!data) return null;
  return {
    ...data,
    content: richTextModify(data.content),
    entryList: data?.entryList?.items?.filter(Boolean) || [],
  };
};

export const formDataModify = (data: any) => {
  if (!data) return null;

  return {
    ...data,
    formFieldList: data?.formFieldList?.items?.filter(Boolean) || [],
  };
};

export const leadsWrapperDataModify = (data: any) => {
  if (!data) return null;
  return {
    ...data,
    form: formDataModify(data?.form),
    valuePropositionList: data?.valuePropositionList?.items?.filter(Boolean) || [],
    customerLogoList: data?.customerLogoList?.items?.filter(Boolean) || [],
  };
};

export const dataSectionDataModify = (data: any) => {
  if (!data) return null;
  return {
    ...data,
    chartList: data?.chartList?.items?.filter(Boolean) || [],
    peopleList: data?.peopleList?.items?.filter(Boolean) || [],
    carrierList: data?.carrierList?.items?.filter(Boolean) || [],
    wordCloud: wordCloudDataModify(data.wordCloud),
  };
};

export const seoSectionDataModify = (data: any) => {
  if (!data) return null;
  return {
    ...data,
    longForm: richTextModify(data.longForm),
  };
};

export const onboardingPopUpDataModify = (data: any) => {
  if (!data || !data.length) return null;

  const randomIndex = Math.floor(Math.random() * data.length);
  data = data[randomIndex];

  return {
    ...data,
    aboutYouForm: formDataModify(data?.aboutYouForm),
    joinUsValuePropositionList: data?.joinUsValuePropositionList?.items?.filter(Boolean) || [],
    followUsValuePropositionList: data?.followUsValuePropositionList?.items?.filter(Boolean) || [],
    aboutYouValuePropositionList: data?.aboutYouValuePropositionList?.items?.filter(Boolean) || [],
    thankYouValuePropositionList: data?.thankYouValuePropositionList?.items?.filter(Boolean) || [],
  };
};

type TCurrentData = {
  joinUsValuePropositionList: { items: Record<string, unknown>[] } & Record<string, unknown>;
};

// This util is used for PMT onboarding popup with a/b testing
export const pmtPopUpDataModify = (data: Record<string, unknown>[]): IPmtOnboardingPopup | null => {
  if (!data?.length) return null;

  const randomIndex = Math.floor(Math.random() * data.length);
  const currentData = data[randomIndex];
  const { joinUsValuePropositionList } = currentData as TCurrentData;

  return {
    ...currentData,
    joinUsValuePropositionList: joinUsValuePropositionList?.items?.filter(Boolean) || [],
  };
};

export const reportsPopupDataModify = (data: any) => {
  if (!data || !data.length) return null;

  const IMAGE_WIDTH = 502; // 335 * 1.5 - max is from Mobile
  const IMAGE_HEIGHT = 270; // 180 * 1.5 - max is from Desktop
  const randomIndex = Math.floor(Math.random() * data.length);
  data = data[randomIndex];
  const { imageDesktop, imageMobile } = data;

  return {
    ...data,
    imageDesktop: imageDesktop && {
      ...imageDesktop,
      url: imageDesktop?.url && `https:${imageDesktop?.url}?w=${IMAGE_WIDTH}&h=${IMAGE_HEIGHT}`,
    },
    imageMobile: imageMobile && {
      ...imageMobile,
      url: imageMobile?.url && `https:${imageMobile?.url}?w=${IMAGE_WIDTH}&h=${IMAGE_HEIGHT}`,
    },
  };
};

const sectionDataModifyMapping: {
  [key in ContentTypeName]?: any;
} = {
  [ContentTypeName['WordCloud']]: wordCloudDataModify,
  [ContentTypeName['LeadGenBanner']]: leadGenBannerDataModify,
  [ContentTypeName['PageOverviewBanner']]: PageOverviewBannerDataModify,
  [ContentTypeName['EventList']]: eventListDataModify,
  [ContentTypeName['Wrapper']]: wrapperDataModify,
  [ContentTypeName['LeadsWrapper']]: leadsWrapperDataModify,
  [ContentTypeName['CarrierSection']]: dataSectionDataModify,
  [ContentTypeName['SeoSection']]: seoSectionDataModify,
};

export const sectionListModify = (data: any): ITag => {
  return {
    ...data,
    sectionList:
      data?.sectionList?.items?.filter(Boolean)?.map((sectionData: { __typename: ContentTypeName }) => {
        if (sectionDataModifyMapping[sectionData.__typename]) {
          return sectionDataModifyMapping[sectionData.__typename](sectionData);
        }
        return sectionData;
      }) || [],
  };
};

type ModifyRightPanelDataArgs = (args: {
  data: any;
  contentType: ContentTypeName;
  sliced?: boolean;
  modifyUtil?: (data: any) => any;
}) => Array<any>;

const modifyRightPanelData: ModifyRightPanelDataArgs = ({ data, contentType, sliced = false, modifyUtil }) => {
  if (!data) return [];
  let finalList =
    data?.list?.items?.filter(Boolean)?.filter(({ __typename }: { __typename: ContentTypeName }) => {
      return __typename === contentType;
    }) || [];

  if (sliced) {
    finalList = finalList?.slice(0, 1);
  }

  if (modifyUtil) {
    finalList = finalList?.map((sectionData: any) => {
      return modifyUtil(sectionData);
    });
  }

  return finalList;
};

export const rightSectionListModify = (data: any) => {
  if (!data) return null;
  return (
    data?.items?.filter(Boolean)?.map((rightPanel: any) => {
      let modifiedList = [];

      switch (rightPanel.type) {
        case RightPanelType.FeaturedCarrierSection:
          modifiedList = modifyRightPanelData({
            data: rightPanel,
            contentType: ContentTypeName.Carrier,
          });
          break;
        case RightPanelType.ReportSection:
          modifiedList = modifyRightPanelData({
            data: rightPanel,
            contentType: ContentTypeName.Article,
            sliced: true,
          });
          break;
        case RightPanelType.LeadGenBanner: {
          modifiedList = modifyRightPanelData({
            data: rightPanel,
            contentType: ContentTypeName.LeadGenBanner,
            sliced: true,
            modifyUtil: leadGenBannerDataModify,
          });
          break;
        }
        case RightPanelType.Ad:
        case RightPanelType.TrackingWidget:
        default:
          modifiedList = [];
          break;
      }

      return {
        ...rightPanel,
        list: modifiedList,
      };
    }) || []
  );
};

export const eventDataModify = (data: any): IEvent => {
  return {
    ...data,
    tags: data?.tags?.items.filter(Boolean) || [],
    content: richTextModify(data?.content),
    rightSectionList: rightSectionListModify(data.rightSectionList),
  };
};

export const landingPageDataModify = (data: any) => {
  if (!data) return null;
  return {
    ...data,
    ...sectionListModify(data),
    rightSectionList: rightSectionListModify(data.rightSectionList),
    content: richTextModify(data.content),
  };
};

export const tagDataModify = (data: any) => {
  if (!data) return null;
  return {
    ...data,
    ...sectionListModify(data),
    rightSectionList: rightSectionListModify(data.rightSectionList),
  };
};

// eslint-disable-next-line @typescript-eslint/ban-types
export const pageDataModify = <T extends {}>(data: any): T | null => {
  if (!data) return null;

  const modifiedData = {
    ...data,
    ...sectionListModify(data),
    rightSectionList: rightSectionListModify(data.rightSectionList),
  };

  if (data.seoDescription) {
    modifiedData.seoDescription = richTextModify(data.seoDescription);
  }

  return modifiedData;
};

/**
 * Modifies translation data by merging default and translated content
 * @param defaultData - The default translation data (usually English)
 * @param data - The translated content for the target language
 * @returns A merged translation object that uses translated content when available, falling back to default content
 * @template T - Type extending {}
 */
export const translationDataModify = <T extends {}>(defaultData: T, data: T, excludeKeys?: string[]): T => {
  if (!data) return {} as T;

  // Iterate through all keys in the translated data object
  // For each key, use the translated value if it exists,
  // otherwise fall back to the default value, or empty string as last resort
  // This ensures we always have a value for each translation key
  return Object.keys(data).reduce((acc, key) => {
    const _key = key as keyof T;

    if (excludeKeys?.includes(key)) {
      acc[_key] = data[_key];
      return acc;
    }

    acc[_key] = data[_key] || defaultData[_key] || ('' as any);
    return acc;
  }, {} as T);
};

/**
 * Gets a random item from an array or returns the input if it's not an array
 * @param data - Input data that can be an array, single item, or null
 * @returns A random item from the array if input is an array, the input item if not an array, or null if input is null
 * @template T - Type of the data/array elements
 * @example
 * // Returns a random item from array
 * getRandomData(['a', 'b', 'c']) // Returns 'a' or 'b' or 'c'
 * // Returns the single item
 * getRandomData('test') // Returns 'test'
 * // Returns null
 * getRandomData(null) // Returns null
 */
export const getRandomData = <T>(data: T[] | T): T => {
  if (!data) return {} as T;
  return Array.isArray(data) ? data[Math.floor(Math.random() * data.length)] : data;
};

export const headerDataModify = (data: any) => {
  if (!data) return null;
  const DRAFT = 'draft';
  const ICON_SIZE = 16 * 2;

  // Filter out draft assets
  const modifiedItemList =
    data?.itemList?.itemList?.filter(Boolean)?.map((item: any) => {
      if (!item.innerData?.length) return item;

      const modifiedInnerData = item.innerData?.map((innerItem: any) => {
        if (!innerItem.icon) return innerItem;
        const status = innerItem.icon.status;
        delete innerItem.icon.status;
        if (status === DRAFT) {
          return {
            ...innerItem,
            icon: null,
          };
        }
        return {
          ...innerItem,
          icon: {
            ...innerItem.icon,
            url: 'https:' + innerItem.icon?.url + `?w=${ICON_SIZE}`,
          },
        };
      });

      return {
        ...item,
        innerData: modifiedInnerData,
      };
    }) || [];

  return {
    ...data,
    itemList: modifiedItemList,
  };
};
