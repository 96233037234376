import { useResponsive } from '@/hooks';
import { type LanguageCode, ResponsiveCollection } from '@/interfaces';

interface StoreBadgeProps {
  platform: 'apple' | 'google';
  url?: string;
  languageCode: LanguageCode;
}

const StoreBadge = ({ platform = 'apple', url, languageCode }: StoreBadgeProps) => {
  const { isDesktopPMT } = useResponsive([ResponsiveCollection.DesktopPMT]);

  const getImgSrc = () => {
    if (platform === 'apple') {
      return `/images/app-badges/${languageCode}/apple-black-${languageCode}.svg`;
    }

    return `/images/app-badges/${languageCode}/google-black-${languageCode}.svg`;
  };

  const getImgSize = () => {
    if (isDesktopPMT) {
      return platform === 'apple' ? { width: 'auto', height: 60 } : { width: 'auto', height: 60 };
    }
    return platform === 'apple' ? { width: 'auto', height: 45 } : { width: 'auto', height: 45 };
  };

  const storeBadge = (
    <img src={getImgSrc()} alt={`${platform} badge`} width={getImgSize().width} height={getImgSize().height} />
  );

  if (!url) return storeBadge;

  return (
    <a href={url} target='_blank' rel='noreferrer'>
      {storeBadge}
    </a>
  );
};

export default StoreBadge;
