import { useGlobalState } from '@/hooks';
import type { Asset } from '@/interfaces';
import Image from 'next/image';
import { useMemo } from 'react';
import { DesktopContainer, MobileContainer } from './GraphicImg.styles';

interface GraphicImgProps {
  image?: Asset;
  imageMobile?: Asset;
  priority?: boolean;
  includedWigdet?: boolean;
}
const GraphicImg: React.FC<GraphicImgProps> = ({ image, imageMobile, priority, includedWigdet }) => {
  const { getProperImageUrl } = useGlobalState();

  const mobileGraphicImage = useMemo(() => {
    if (!imageMobile) return null;

    return (
      <MobileContainer>
        <span style={{ float: 'right' }}>
          <Image
            src={getProperImageUrl(imageMobile)}
            alt={imageMobile.description || 'parcel monitor banner'}
            priority={priority}
            quality={100}
            sizes='100vw'
            width={211}
            height={147}
            style={{
              objectFit: 'contain',
              objectPosition: 'top',
            }}
          />
        </span>
      </MobileContainer>
    );
  }, [imageMobile, includedWigdet, priority, getProperImageUrl]);

  const desktopGraphicImage = useMemo(() => {
    if (!image) return null;

    return (
      <DesktopContainer>
        <Image
          src={getProperImageUrl(image)}
          alt={image.description || 'parcel monitor banner'}
          priority={priority}
          quality={100}
          width={includedWigdet ? 720 : 500}
          height={includedWigdet ? 269 : 258.51}
          style={{
            objectFit: 'contain',
            objectPosition: 'top',
          }}
        />
      </DesktopContainer>
    );
  }, [image, includedWigdet, priority, getProperImageUrl]);

  return (
    <>
      {mobileGraphicImage}
      {desktopGraphicImage}
    </>
  );
};

export default GraphicImg;
