import { useGlobalState } from '@/hooks';
import type { Asset } from '@/interfaces';
import { type ImageProps, getImageProps } from 'next/image';
import type { CSSProperties } from 'react';
import Video from './Video';
import { isImage, isVideo } from './utils';

type AssetRendererProps = {
  asset?: Asset;
  className?: string;
  VideoComponent?: React.FC;
  videoProps?: {
    height?: {
      mobile?: number | string;
      desktop?: number | string;
    };
    style?: CSSProperties;
  };
  imageProps?: {
    // for next/image
    width?: ImageProps['width'];
    height?: ImageProps['height'];
    quality?: ImageProps['quality'];
    // for Product Mockup
    ref?: React.Ref<HTMLImageElement>;
    onLoad?: () => void;
    // for styled component
    [key: string]: unknown;
  };
};

const AssetRenderer: React.FC<AssetRendererProps> = ({ asset, VideoComponent = Video, videoProps, imageProps }) => {
  const { getProperImageUrl } = useGlobalState();

  if (!asset) return null;

  if (isImage(asset)) {
    const { props } = getImageProps({
      src: getProperImageUrl(asset),
      alt: asset.description || 'Image',
      width: imageProps?.width || 500,
      height: imageProps?.height || 500,
      priority: true,
      ...imageProps,
    });

    // eslint-disable-next-line @next/next/no-img-element
    return <img {...props} alt={asset.description || 'Image'} decoding='sync' />;
  }

  if (isVideo(asset)) {
    return <VideoComponent {...videoProps} asset={asset} />;
  }

  return null;
};

export default AssetRenderer;
