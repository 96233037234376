import Image from 'next/image';
import Link from 'next/link';

import { AppBadge, Container } from '@/components';
import {
  BottomSection,
  ColumnsInnerContent,
  Copyright,
  CopyrightAndPageLinks,
  FooterInner,
  FooterWrapper,
  ImageContainer,
  LeftSection,
  Logo,
  PPText,
  PoweredByPP,
  SubText,
} from './Footer.styles';

import { PARCEL_PERFORM_DOMAIN, ROUTES, RouteName, TextStyles } from '@/constants';

import { useGlobalState, useResponsive } from '@/hooks';
import { type IFooter, ResponsiveCollection } from '@/interfaces';
import { isOpenInNewTab as _isOpenInNewTab, getPmtLangHref, gtmClickPmc, isTrackMyParcelLink } from '@/utils';
import Columns from './Columns';
import PageLinks from './PageLinks';
import SocialLinks from './SocialLinks';

const Footer: React.FC<IFooter & { onlyLogo?: boolean; hideAppBadge?: boolean }> = ({
  subText,
  itemList,
  copyright,
  pageLinks,
  ppText,
  onlyLogo,
  appBadgeMainText,
  appBadgeSubText,
  appStoreUrl,
  googlePlayUrl,
  hideAppBadge,
}) => {
  const { isDesktop } = useResponsive([ResponsiveCollection.Desktop]);
  const { isMyParcelsPage, pmtCurrentLanguageCode } = useGlobalState();

  const isOpenInNewTab = (href?: string) => {
    // If not at PMT, open in new tab for external, open in current tab for internal
    if (!isMyParcelsPage) return _isOpenInNewTab(href || '#');

    // If at PMT, open in new tab for non-PMT, open in current tab for PMT
    if (!href || isTrackMyParcelLink(href)) {
      return undefined;
    }
    return '_blank';
  };

  const onLinkClick = (href?: string) => {
    if (isMyParcelsPage) {
      gtmClickPmc(href || '#');
    }
  };

  const logoHref = isMyParcelsPage ? getPmtLangHref(pmtCurrentLanguageCode) : ROUTES[RouteName.Home].path;

  const footerBackgroundImageSrc = isDesktop
    ? onlyLogo
      ? '/images/footer-background-only-logo.svg'
      : '/images/footer-background.svg'
    : onlyLogo
      ? '/images/mobile-footer-background-only-logo.svg'
      : '/images/mobile-footer-background.svg';

  return (
    <FooterWrapper id='footer'>
      <FooterInner>
        <ImageContainer>
          <Image
            src={footerBackgroundImageSrc}
            alt='footer background'
            fill
            sizes='100vw'
            style={{
              objectPosition: 'bottom',
              objectFit: isDesktop && !onlyLogo ? 'contain' : 'cover',
            }}
          />
        </ImageContainer>
        <Container style={{ position: 'relative' }}>
          <ColumnsInnerContent onlyLogo={onlyLogo}>
            <LeftSection>
              <Logo href={logoHref} onlyLogo={onlyLogo}>
                <Image src='/images/icons/monitor_logo.svg' alt='Parcel Monitor Logo' width={240} height={27} />
              </Logo>
              {subText && <SubText variant={TextStyles['Body Text Small']}>{subText}</SubText>}
              <Link
                href={`https://${PARCEL_PERFORM_DOMAIN}?utm_source=parcelmonitor&utm_medium=referral&utm_content=initiated-by-PP-button`}
                passHref
                legacyBehavior
              >
                <PoweredByPP target='_blank'>
                  <PPText variant={TextStyles['Body Text Small']}>{ppText}</PPText>
                  <Image src='/images/pp_text.svg' alt='powered by Parcel Perform' width={118} height={19.05} />
                </PoweredByPP>
              </Link>
            </LeftSection>
            {!onlyLogo && itemList && (
              <Columns itemList={itemList} onLinkClick={onLinkClick} isOpenInNewTab={isOpenInNewTab} />
            )}
            {!hideAppBadge && (
              <AppBadge
                mainText={appBadgeMainText}
                subText={appBadgeSubText}
                appStoreUrl={appStoreUrl}
                googlePlayUrl={googlePlayUrl}
              />
            )}
            <BottomSection onlyLogo={onlyLogo}>
              {!onlyLogo && <SocialLinks />}
              <CopyrightAndPageLinks onlyLogo={onlyLogo}>
                <Copyright>{copyright}</Copyright>
                {pageLinks && (
                  <PageLinks pageLinks={pageLinks} onLinkClick={onLinkClick} isOpenInNewTab={isOpenInNewTab} />
                )}
              </CopyrightAndPageLinks>
            </BottomSection>
          </ColumnsInnerContent>
        </Container>
      </FooterInner>
    </FooterWrapper>
  );
};

export default Footer;
