import { PageWithGraphic, SEOSection } from '@/components';
import { ContentTypeName, MasterTranslation } from '@/constants';
import { useGlobalState } from '@/hooks';
import type { IPmtPage } from '@/modules/pmt/interfaces';
import { capitalize, getPmtLangHref, renderHTML } from '@/utils';
import { useSearchParams } from 'next/navigation';
import type React from 'react';
import { PageTitle, UnsubscribeSingleWrapper, ViewMyParcelsButton } from './UnsubscribeSingle.styles';

interface IUnsubscribeSingle {
  pmtPageData: IPmtPage | null;
}

const UnsubscribeSingle: React.FC<IUnsubscribeSingle> = ({ pmtPageData }) => {
  const { t, pmtCurrentLanguageCode } = useGlobalState();
  const searchParams = useSearchParams();
  const parcelId = searchParams.get('parcelId');

  const renderPageTitle = () => {
    if (!parcelId) return null;
    let pageTitle = t(MasterTranslation.UnsubSingleSuccessMainText);

    pageTitle = pageTitle.replace('{tracking_id}', `<span>${parcelId}</span>`);

    return renderHTML(pageTitle);
  };

  const foundSeoSection = pmtPageData?.sectionList?.find(
    (section: any) => capitalize(section.__typename) === ContentTypeName.SeoSection,
  );

  return (
    <>
      <UnsubscribeSingleWrapper>
        <PageWithGraphic>
          <PageTitle>{renderPageTitle()}</PageTitle>
          <ViewMyParcelsButton href={getPmtLangHref(pmtCurrentLanguageCode)}>
            {t(MasterTranslation.ViewMyParcels)}
          </ViewMyParcelsButton>
        </PageWithGraphic>
      </UnsubscribeSingleWrapper>
      {foundSeoSection && <SEOSection {...foundSeoSection} />}
    </>
  );
};

export default UnsubscribeSingle;
