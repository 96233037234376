import { useInView } from 'framer-motion';
import { useEffect } from 'react';

export const useTriggerWhenFooterIsInView = (action: () => void) => {
  const isInView = useInView(
    { current: typeof document !== 'undefined' ? document.getElementById('footer') : null },
    { once: true },
  );

  useEffect(() => {
    if (isInView) action();
  }, [action, isInView]);
};
